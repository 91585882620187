<template>
  <v-row dense>
    <v-col>
      <v-text-field
        v-model="itemClone.display_name"
        :loading="loading.update"
        :disabled="loading.update"
        label="Display Name"
        hide-details
        clearable
        outlined
        dense
        @input="$emit('input')"
      />
    </v-col>
    <v-col :cols="2">
      <v-text-field
        :value="_.kebabCase(itemClone.display_name)"
        :loading="loading.update"
        label="Unique Name"
        hide-details
        readonly
        disabled
        outlined
        dense
      />
    </v-col>
    <v-col>
      <v-text-field
        v-model="itemClone.description"
        :loading="loading.update"
        :disabled="loading.update"
        label="Description"
        hide-details
        clearable
        outlined
        dense
        @input="$emit('input')"
      />
    </v-col>
    <v-col :cols="2">
      <v-select
        v-model="itemClone.type"
        :items="['text', 'number']"
        :loading="loading.update"
        :disabled="loading.update"
        label="Type"
        hide-details
        required
        outlined
        dense
        @input="$emit('input')"
      />
    </v-col>
    <v-col class="d-flex align-center flex-grow-0">
      <v-btn
        class="mx-0"
        color="blue"
        :loading="loading.update"
        :disabled="loading.update || !isDirty"
        depressed
        x-small
        fab
        @click="updateMutation"
      >
        <v-icon>
          mdi-content-save
        </v-icon>
      </v-btn>
    </v-col>
    <v-col class="d-flex align-center flex-grow-0">
      <v-btn
        class="mx-0"
        color="red"
        :loading="loading.delete"
        :disabled="loading.delete"
        depressed
        x-small
        fab
        @click="deleteMutation"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ManageEventCommentCategoriesItem',
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    itemClone: null,
    loading: {
      update: false,
      delete: false
    },
    allowedKeys: [
      'display_name',
      'description',
      'type'
    ]
  }),
  computed: {
    onlyDirty () {
      return this._.pick(this._.deepDiff(this.itemClone, this.item), this.allowedKeys)
    },
    isDirty () {
      return this._.keys(this.onlyDirty).length
    }
  },
  created () {
    this.cloneItem()
  },
  methods: {
    cloneItem () {
      this.itemClone = this._.cloneDeep(this.item)
      this.$emit('input')
    },
    async updateMutation () {
      this.loading.update = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/updateEventCommentCategory').default,
          variables: {
            id: this.item.id,
            value: this.onlyDirty,
            update: (store, { data: { updateEventCommentCategory } }) => {
              const cachedData = store.readQuery({ query: require('@/gql/getEvents').default })
              const data = this._.cloneDeep(cachedData)
              const currentlyCachedEvent = this._.find(data.events, { id: this.event.id })
              currentlyCachedEvent.commentCategories = this._.unionBy(currentlyCachedEvent.commentCategories, [updateEventCommentCategory], 'id')
              store.writeQuery({ query: require('@/gql/getEvents').default, data })
            }
          }
        })
        this.$notify({
          group: 'dashboard',
          type: 'success',
          title: 'Successfully updated Comment Category'
        })
        this.cloneItem()
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.update = false
    },
    async deleteMutation () {
      this.loading.delete = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/deleteEventCommentCategory').default,
          variables: { id: this.item.id },
          update: (store, { data: { deleteEventCommentCategory } }) => {
            const cachedData = store.readQuery({ query: require('@/gql/getEvents').default })
            const data = this._.cloneDeep(cachedData)
            const currentlyCachedEvent = this._.find(data.events, { id: this.event.id })
            currentlyCachedEvent.commentCategories = currentlyCachedEvent.commentCategories.filter(category => category.id !== deleteEventCommentCategory.id)
            store.writeQuery({ query: require('@/gql/getEvents').default, data })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteEventCommentCategory: {
              __typename: 'EventCommentCategory',
              id: this.item.id
            }
          }
        })
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.delete = false
    }
  }
}
</script>
