<template>
  <v-card outlined>
    <v-card-text>
      <v-row dense>
        <v-col>
          <v-combobox
            v-model="selectedCategory"
            label="Categories"
            item-value="id"
            item-text="display_name"
            :items="commentCategories.filter(category => !event.commentCategories.map(category => category.id).includes(category.id))"
            :loading="$apollo.queries.commentCategories.loading || loading.create"
            :disabled="$apollo.queries.commentCategories.loading || loading.create"
            return-object
            hide-details
            clearable
            outlined
            @change="createMutation"
          />
        </v-col>
      </v-row>
      <v-row
        class="mb-4"
        dense
      >
        <v-col>
          <v-text-field
            v-model="filterString"
            label="Filter"
            hide-details
            clearable
            outlined
          />
        </v-col>
        <v-col>
          <v-select
            v-model="selectedOrder"
            label="Order"
            :items="['name', 'display_name', 'created']"
            hide-details
            outlined
          />
        </v-col>
        <v-col class="d-flex align-center flex-grow-0">
          <v-btn
            icon
            @click="selectedOrderDirection = !selectedOrderDirection"
          >
            <v-icon>
              {{ selectedOrderDirection ? 'mdi-sort-ascending' : 'mdi-sort-descending' }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <manage-event-comment-categories-item
            v-for="category of orderedList"
            :key="category.id"
            ref="category"
            :event="event"
            :item="category"
            @input="updateDirtyAvailable"
          />
          <v-alert
            v-if="!event.commentCategories.length"
            class="mb-0"
            color="grey lighten-1"
            dark
          >
            <div class="text-h6">
              The list is empty.
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn
        color="blue"
        :disabled="!dirtyAvailable"
        depressed
        @click="updateAll"
      >
        Save All
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ManageEventCommentCategoriesItem from './ManageEventCommentCategoriesItem'
export default {
  name: 'ManageEventCommentCategories',
  components: { ManageEventCommentCategoriesItem },
  props: {
    event: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    commentCategories: [],
    selectedCategory: null,
    selectedOrder: 'created',
    selectedOrderDirection: false,
    filterString: '',
    loading: {
      create: false
    },
    dirtyAvailable: false
  }),
  computed: {
    filteredList () {
      if (!this.filterString) return this.event.commentCategories
      return this.event.commentCategories.filter(category => category.display_name.toLowerCase().includes(this.filterString))
    },
    orderedList () {
      return this._.orderBy(this.filteredList, [this.selectedOrder], [this.selectedOrderDirection ? 'asc' : 'desc'])
    }
  },
  methods: {
    async createMutation (input) {
      if (!input) return
      this.loading.create = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/createEventCommentCategory').default,
          variables: { event_id: this.event.id, value: { display_name: typeof input === 'object' ? input.display_name : input, type: 'text' } },
          update: (store, { data: { createEventCommentCategory } }) => {
            const cachedData = store.readQuery({ query: require('@/gql/getEvents').default })
            const data = this._.cloneDeep(cachedData)
            const currentlyCachedEvent = this._.find(data.events, { id: this.event.id })
            currentlyCachedEvent.commentCategories = this._.unionBy(currentlyCachedEvent.commentCategories, [createEventCommentCategory], 'id')
            store.writeQuery({ query: require('@/gql/getEvents').default, data })
          }
        })
        this.selectedCategory = null
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.create = false
    },
    updateDirtyAvailable () {
      if (!this.$refs.category) this.dirtyAvailable = false
      else this.dirtyAvailable = this._.some(this.$refs.category.map(ref => ref.isDirty))
    },
    updateAll () {
      if (!this.$refs.category) return
      for (const ref of this.$refs.category) {
        if (ref.isDirty) ref.updateMutation()
      }
    }
  },
  apollo: {
    commentCategories: {
      query: require('@/gql/getEventCommentCategories').default
    }
  }
}
</script>
