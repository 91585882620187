<template>
  <v-card outlined>
    <v-card-text>
      <v-alert
        v-if="!event.is_private"
        type="warning"
        prominent
      >
        <div class="text-h6">
          Added users will be able to access this <strong>Event's</strong> settings and edit them.
        </div>
      </v-alert>
      <v-autocomplete
        v-model="selectedUser"
        label="Search Users"
        item-value="id"
        item-text="name"
        :items="users.filter(user => ![event.owner.id, ...event.moderators.map(moderator => moderator.user.id)].includes(user.id))"
        :loading="$apollo.queries.users.loading || loading.create"
        :disabled="$apollo.queries.users.loading || loading.create"
        return-object
        outlined
        @change="createMutation"
      />
      <manage-event-moderators-item
        v-for="user of event.moderators"
        :key="user.id"
        :event="event"
        :item="user"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ManageEventModeratorsItem from './ManageEventModeratorsItem'
export default {
  name: 'ManageEventModerators',
  components: { ManageEventModeratorsItem },
  props: {
    event: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    users: [],
    selectedUser: null,
    loading: {
      create: false
    }
  }),
  methods: {
    async createMutation (user) {
      this.loading.create = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/createEventModerator').default,
          variables: { event_id: this.event.id, value: { user_id: user.id } },
          update: (store, { data: { createEventModerator } }) => {
            const cachedData = store.readQuery({ query: require('@/gql/getEvents').default })
            const data = this._.cloneDeep(cachedData)
            const currentlyCachedEvent = this._.find(data.events, { id: this.event.id })
            currentlyCachedEvent.moderators = this._.unionBy(currentlyCachedEvent.moderators, [createEventModerator], 'id')
            store.writeQuery({ query: require('@/gql/getEvents').default, data })
          }
        })
        this.selectedUser = null
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.create = false
    }
  },
  apollo: {
    users: {
      query: require('@/gql/getUsers').default
    }
  }
}
</script>
