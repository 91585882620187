<template>
  <v-card outlined>
    <v-card-text>
      <v-row dense>
        <v-col :cols="6">
          <v-text-field
            v-model="eventClone.name"
            :rules="[v => !!v || 'This field is required']"
            label="Event name"
            outlined
            dense
          />
        </v-col>
        <v-col :cols="6">
          <v-text-field
            :value="eventClone.slug"
            label="Slug (Read-Only)"
            hide-details
            disabled
            outlined
            dense
          />
        </v-col>
        <v-col :cols="12">
          <mavon-editor
            ref="editor-0"
            v-model="eventClone.description"
            class="mb-6"
            :toolbars="require('@/mavonToolbars').default"
            :box-shadow="false"
            language="en"
            @imgAdd="(pos, $file) => $imgAdd(pos, $file, 'editor-0')"
          />
        </v-col>
        <v-col :cols="4">
          <v-text-field
            v-model.number="eventClone.min_participants"
            label="Minimum Participants per Project"
            :rules="[
              (v) => !!v || 'This field is required',
              (v) => _.isNumber(v) && parseInt(v) > 0 || 'Must be a positive numerical value',
              (v) => v <= eventClone.max_participants || 'Must be lower or equal to Maximum Participants',
            ]"
            type="number"
            outlined
            dense
          />
        </v-col>
        <v-col :cols="4">
          <v-text-field
            v-model.number="eventClone.preferred_participants"
            label="Preferred Participants per Project"
            :rules="[
              (v) => !!v || 'This field is required',
              (v) => _.isNumber(v) && parseInt(v) > 0 || 'Must be a positive numerical value',
              (v) => v <= eventClone.max_participants && v >= eventClone.min_participants || 'Must be between to Minimum and Maximum Participants',
            ]"
            type="number"
            outlined
            dense
          />
        </v-col>
        <v-col :cols="4">
          <v-text-field
            v-model.number="eventClone.max_participants"
            label="Maximum Participants per Project"
            :rules="[
              (v) => !!v || 'This field is required',
              (v) => _.isNumber(v) && parseInt(v) > 0 || 'Must be a positive numerical value',
              (v) => v >= eventClone.min_participants || 'Must be higher or equal to Minimum Participants',
            ]"
            type="number"
            outlined
            dense
          />
        </v-col>
        <v-col :cols="4">
          <v-switch
            v-model="eventClone.is_draft"
            :true-value="true"
            :false-value="false"
            class="mt-0"
            label="Draft (hidden from everyone else)"
            hide-details
          />
        </v-col>
        <v-col :cols="4">
          <v-switch
            v-model="eventClone.is_private"
            :true-value="true"
            :false-value="false"
            class="mt-0"
            label="Private (only invited users)"
            hide-details
          />
        </v-col>
        <v-col :cols="4">
          <v-switch
            v-model="eventClone.is_active"
            :true-value="true"
            :false-value="false"
            class="mt-0"
            label="Active (users can edit cards)"
            hide-details
          />
        </v-col>
      </v-row>
      <v-divider class="my-4 mb-2" />
      <v-row>
        <v-col :cols="4">
          <v-switch
            v-model="eventClone.show_document_field"
            :true-value="true"
            :false-value="false"
            class="mt-0"
            label="Show Document Field"
            hide-details
          />
        </v-col>
        <v-col :cols="4">
          <v-switch
            v-model="eventClone.show_github_repository_field"
            :true-value="true"
            :false-value="false"
            class="mt-0"
            label="Show GitHub Repository Field"
            hide-details
          />
        </v-col>
        <v-col :cols="4">
          <v-switch
            v-model="eventClone.show_slack_channel_field"
            :true-value="true"
            :false-value="false"
            class="mt-0"
            label="Show Slack Channel Field"
            hide-details
          />
        </v-col>
        <v-col :cols="4">
          <v-switch
            v-model="eventClone.show_video_field"
            :true-value="true"
            :false-value="false"
            class="mt-0"
            label="Show Video Field"
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="green"
        :loading="loading.update"
        :disabled="loading.update || !isDirty"
        depressed
        @click="updateMutation"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ManageEventGeneral',
  props: {
    event: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    eventClone: null,
    loading: {
      update: false
    },
    allowedKeys: [
      'name',
      'description',
      'min_participants',
      'preferred_participants',
      'max_participants',
      'is_draft',
      'is_private',
      'is_active',
      'show_document_field',
      'show_github_repository_field',
      'show_slack_channel_field',
      'show_video_field'
    ]
  }),
  computed: {
    onlyDirty () {
      return this._.pick(this._.deepDiff(this.eventClone, this.event), this.allowedKeys)
    },
    isDirty () {
      return this._.keys(this.onlyDirty).length
    }
  },
  created () {
    this.cloneItem()
  },
  methods: {
    cloneItem () {
      this.eventClone = this._.cloneDeep(this.event)
    },
    async $imgAdd (pos, $file, refName) {
      try {
        const formData = new FormData()
        formData.append('event', this.event.id)
        formData.append('image', $file)
        const response = await this.$api.post(
          '/upload-image',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        if (!response.data.ok) {
          throw new Error(response.data.output)
        } else {
          this.$refs[refName].$img2Url(pos, response.data.output.viewImage)
        }
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'Mavon Error',
          text: error.message,
          duration: 10000
        })
      }
    },
    async updateMutation () {
      this.loading.update = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/updateEvent').default,
          variables: { id: this.event.id, value: this.onlyDirty },
          update: (store, { data: { updateEvent } }) => {
            const cachedData = store.readQuery({ query: require('@/gql/getEvents').default })
            const data = this._.cloneDeep(cachedData)
            data.events = this._.unionBy(data.events, [updateEvent], 'id')
            store.writeQuery({ query: require('@/gql/getEvents').default, data })
          }
        })
        this.$notify({
          group: 'dashboard',
          type: 'success',
          title: 'Successfully updated Event'
        })
        this.cloneItem()
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.update = false
    }
  }
}
</script>
