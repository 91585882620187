<template>
  <div>
    <v-list-item
      color="primary"
      active-class="primary o-100"
      :class="{ 'o-60': muted }"
      link
      v-bind="$attrs"
    >
      <v-list-item-content class="white--text">
        <v-list-item-title>
          <span
            v-if="event.is_draft"
            class="mr-1"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-shield-edit
                </v-icon>
              </template>
              <span>DRAFT</span>
            </v-tooltip>
          </span>
          <span
            v-if="event.is_private"
            class="mr-1"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-shield-key
                </v-icon>
              </template>
              <span>PRIVATE</span>
            </v-tooltip>
          </span>
          <span>
            {{ event.name }}
          </span>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action
        v-if="currentUserIsOwner || currentUserIsModerator"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :loading="loading.delete || loading.update"
              x-small
              icon
              v-bind="attrs"
              v-on="on"
              @click.prevent="() => {}"
            >
              <v-icon small>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="dialog = true"
            >
              <v-list-item-title>
                Edit Event
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="`/report/${event.slug}/`"
            >
              <v-list-item-title>
                Open Report
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="currentUserIsOwner"
              @click="updateMutation"
            >
              <v-list-item-title>
                {{ event.is_enabled ? 'Mark Event as Dropped' : 'Mark Event as Active' }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="currentUserIsOwner"
              @click="deleteMutation"
            >
              <v-list-item-title
                class="red--text"
              >
                Delete Event
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>
    <example-dialog
      v-model="dialog"
      title="Edit Event"
      :tabs="[
        {
          name: 'General',
          component: require('./EditEvent/ManageEventGeneral').default,
          props: {
            event,
          },
        },
        {
          name: 'Milestones',
          component: require('./EditEvent/ManageEventMilestones').default,
          props: {
            event,
          },
        },
        {
          name: 'Moderators',
          component: require('./EditEvent/ManageEventModerators').default,
          props: {
            event,
          },
        },
        {
          name: 'Invited Users',
          component: require('./EditEvent/ManageEventUsers').default,
          props: {
            event,
          },
        },
        {
          name: 'Tags',
          component: require('./EditEvent/ManageEventTags').default,
          props: {
            event,
          },
        },
        {
          name: 'Comment Categories',
          component: require('./EditEvent/ManageEventCommentCategories').default,
          props: {
            event,
          },
        },
        {
          name: 'Comment Resolutions',
          component: require('./EditEvent/ManageEventCommentResolutions').default,
          props: {
            event,
          },
        },
      ]"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ExampleDialog from './ExampleDialog.vue'
export default {
  name: 'EventMenuItem',
  components: { ExampleDialog },
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    muted: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: {
      update: false,
      delete: false
    },
    dialog: false
  }),
  computed: {
    ...mapState('user', ['claims']),
    // Ownership
    currentUserIsOwner () {
      return this.event.owner.id === this.claims.sub
    },
    currentUserIsModerator () {
      return !!this._.find(this.event.moderators, moderator => moderator.user.id === this.claims.sub)
    }
  },
  methods: {
    async updateMutation () {
      this.loading.update = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/updateEvent').default,
          variables: {
            id: this.event.id,
            value: { is_enabled: !this.event.is_enabled },
            update: (store, { data: { updateEvent } }) => {
              const cachedData = store.readQuery({ query: require('@/gql/getEvents').default })
              const data = this._.cloneDeep(cachedData)
              data.events = this._.unionBy(data.events, [updateEvent], 'id')
              store.writeQuery({ query: require('@/gql/getEvents').default, data })
            }
          }
        })
        this.$notify({
          group: 'dashboard',
          type: 'success',
          title: 'Successfully updated Event'
        })
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.update = false
    },
    async deleteMutation () {
      const execute = await this.$confirm(
          `
            Do you really want to delete this event?<br />
            Please consider marking it as dropped instead.<br /><br />
            <strong class="red--text">This cannot be undone! All related data will be also deleted!</strong>
          `,
          {
            color: 'red',
            icon: 'mdi-alert'
          }
      )
      if (!execute) return
      this.loading.delete = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/deleteEvent').default,
          variables: { id: this.event.id },
          update: (store, { data: { deleteEvent } }) => {
            const cachedData = store.readQuery({ query: require('@/gql/getEvents').default })
            const data = this._.cloneDeep(cachedData)
            data.events = data.events.filter(event => event.id !== deleteEvent.id)
            store.writeQuery({ query: require('@/gql/getEvents').default, data })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteEvent: {
              __typename: 'Event',
              id: this.event.id
            }
          }
        })
        this.$notify({
          group: 'dashboard',
          type: 'success',
          title: 'Successfully deleted Event'
        })
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.delete = false
    }
  }

}
</script>
