<template>
  <v-row dense>
    <v-col class="d-flex align-center flex-grow-0">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="itemClone.color"
            depressed
            x-small
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-palette
            </v-icon>
          </v-btn>
        </template>
        <v-color-picker
          v-model="itemClone.color"
          value="#E91E63"
          hide-canvas
          hide-inputs
          show-swatches
          @input="$emit('input')"
        />
      </v-menu>
    </v-col>
    <v-col>
      <v-menu
        v-model="datePickerMenuIsOpen"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="itemClone.date_text"
            :disabled="loading.update"
            label="Date"
            hide-details
            clearable
            readonly
            outlined
            dense
            v-bind="attrs"
            v-on="on"
            @input="$emit('input')"
          />
        </template>
        <v-date-picker
          v-model="itemClone.date_text"
          @input="() => { datePickerMenuIsOpen = false; $emit('input') }"
        />
      </v-menu>
    </v-col>
    <v-col>
      <v-text-field
        v-model="itemClone.title"
        :disabled="loading.update"
        label="Title"
        hide-details
        clearable
        outlined
        dense
        @input="$emit('input')"
      />
    </v-col>
    <v-col>
      <v-text-field
        v-model="itemClone.description"
        :disabled="loading.update"
        label="Description"
        hide-details
        clearable
        outlined
        dense
        @input="$emit('input')"
      />
    </v-col>
    <v-col class="d-flex align-center flex-grow-0">
      <v-btn
        class="mx-0"
        color="blue"
        :loading="loading.update"
        :disabled="loading.update || !isDirty"
        depressed
        x-small
        fab
        @click="updateMutation"
      >
        <v-icon>
          mdi-content-save
        </v-icon>
      </v-btn>
    </v-col>
    <v-col class="d-flex align-center flex-grow-0">
      <v-btn
        class="mx-0"
        color="red"
        :loading="loading.delete"
        :disabled="loading.delete"
        depressed
        x-small
        fab
        @click="deleteMutation"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ManageEventMilestonesItem',
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    itemClone: null,
    loading: {
      update: false,
      delete: false
    },
    allowedKeys: [
      'color',
      'date_text',
      'title',
      'description'
    ],
    datePickerMenuIsOpen: false
  }),
  computed: {
    onlyDirty () {
      return this._.pick(this._.deepDiff(this.itemClone, this.item), this.allowedKeys)
    },
    isDirty () {
      return this._.keys(this.onlyDirty).length
    }
  },
  created () {
    this.cloneItem()
  },
  methods: {
    cloneItem () {
      this.itemClone = this._.cloneDeep(this.item)
      this.$emit('input')
    },
    async updateMutation () {
      this.loading.update = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/updateEventMilestone').default,
          variables: {
            id: this.item.id,
            value: this.onlyDirty,
            update: (store, { data: { updateEventMilestone } }) => {
              const cachedData = store.readQuery({ query: require('@/gql/getEvents').default })
              const data = this._.cloneDeep(cachedData)
              const currentlyCachedEvent = this._.find(data.events, { id: this.event.id })
              currentlyCachedEvent.milestones = this._.unionBy(currentlyCachedEvent.milestones, [updateEventMilestone], 'id')
              store.writeQuery({ query: require('@/gql/getEvents').default, data })
            }
          }
        })
        this.$notify({
          group: 'dashboard',
          type: 'success',
          title: 'Successfully updated Milestone'
        })
        this.cloneItem()
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.update = false
    },
    async deleteMutation () {
      this.loading.delete = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/deleteEventMilestone').default,
          variables: { id: this.item.id },
          update: (store, { data: { deleteEventMilestone } }) => {
            const cachedData = store.readQuery({ query: require('@/gql/getEvents').default })
            const data = this._.cloneDeep(cachedData)
            const currentlyCachedEvent = this._.find(data.events, { id: this.event.id })
            currentlyCachedEvent.milestones = currentlyCachedEvent.milestones.filter(milestone => milestone.id !== deleteEventMilestone.id)
            store.writeQuery({ query: require('@/gql/getEvents').default, data })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteEventMilestone: {
              __typename: 'EventMilestone',
              id: this.item.id
            }
          }
        })
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.delete = false
    }
  }
}
</script>
