<template>
  <v-row dense>
    <v-col class="d-flex align-center flex-grow-0">
      <v-avatar
        :size="32"
      >
        <img
          :src="item.user.slack_picture"
          :alt="item.user.name"
        >
      </v-avatar>
    </v-col>
    <v-col class="d-flex align-center">
      {{ item.user.name }}
    </v-col>
    <v-col class="d-flex align-center flex-grow-0">
      <v-btn
        class="mx-0"
        color="red"
        :loading="loading.delete"
        :disabled="loading.delete"
        depressed
        x-small
        fab
        @click="deleteMutation"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ManageEventModeratorsItem',
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: {
      delete: false
    }
  }),
  methods: {
    async deleteMutation () {
      this.loading.delete = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/deleteEventModerator').default,
          variables: { id: this.item.id },
          update: (store, { data: { deleteEventModerator } }) => {
            const cachedData = store.readQuery({ query: require('@/gql/getEvents').default })
            const data = this._.cloneDeep(cachedData)
            const currentlyCachedEvent = this._.find(data.events, { id: this.event.id })
            currentlyCachedEvent.moderators = currentlyCachedEvent.moderators.filter(moderator => moderator.id !== deleteEventModerator.id)
            store.writeQuery({ query: require('@/gql/getEvents').default, data })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteEventModerator: {
              __typename: 'EventModerator',
              id: this.item.id
            }
          }
        })
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.delete = false
    }
  }
}
</script>
