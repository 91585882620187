<template>
  <v-card outlined>
    <v-card-text>
      <v-row dense>
        <v-col>
          <manage-event-milestones-item
            v-for="milestone of event.milestones"
            :key="milestone.id"
            ref="milestone"
            :event="event"
            :item="milestone"
            @input="updateDirtyAvailable"
          />
          <v-alert
            v-if="!event.milestones.length"
            class="mb-0"
            color="grey lighten-1"
            dark
          >
            <div class="text-h6">
              The list is empty.
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn
        color="blue"
        :disabled="!dirtyAvailable"
        depressed
        @click="updateAll"
      >
        Save All
      </v-btn>
      <v-spacer />
      <v-btn
        color="green"
        :loading="loading.create"
        :disabled="loading.create"
        depressed
        @click="createMutation"
      >
        <v-icon
          class="mx-0"
          left
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ManageEventMilestonesItem from './ManageEventMilestonesItem'
export default {
  name: 'ManageEventMiletones',
  components: { ManageEventMilestonesItem },
  props: {
    event: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: {
      create: false
    },
    dirtyAvailable: false
  }),
  computed: {
  },
  methods: {
    async createMutation (input) {
      if (!input) return
      this.loading.create = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/createEventMilestone').default,
          variables: { event_id: this.event.id, value: {} },
          update: (store, { data: { createEventMilestone } }) => {
            const cachedData = store.readQuery({ query: require('@/gql/getEvents').default })
            const data = this._.cloneDeep(cachedData)
            const currentlyCachedEvent = this._.find(data.events, { id: this.event.id })
            currentlyCachedEvent.milestones = this._.unionBy(currentlyCachedEvent.milestones, [createEventMilestone], 'id')
            store.writeQuery({ query: require('@/gql/getEvents').default, data })
          }
        })
        this.selectedMilestone = null
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.create = false
    },
    updateDirtyAvailable () {
      if (!this.$refs.milestone) this.dirtyAvailable = false
      else this.dirtyAvailable = this._.some(this.$refs.milestone.map(ref => ref.isDirty))
    },
    updateAll () {
      if (!this.$refs.milestone) return
      for (const ref of this.$refs.milestone) {
        if (ref.isDirty) ref.updateMutation()
      }
    }
  }
}
</script>
