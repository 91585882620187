<template>
  <v-navigation-drawer
    v-model="drawer"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :width="300"
    src="/sidebar.png"
    dark
    app
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, rgba(0, 0, 0, .6), rgba(0, 0, 0, .4)`"
        v-bind="props"
      />
    </template>
    <v-overlay
      v-if="$apollo.queries.events.loading"
      absolute
    >
      <v-progress-circular indeterminate />
    </v-overlay>
    <div class="d-flex align-center pa-4 white--text">
      <fa-icon
        class="mr-4"
        size="2x"
        :icon="['fab', 'unity']"
      />
      <div class="text-h6 font-weight-regular">
        {{ $t('unity') }}
      </div>
    </div>
    <v-divider class="mx-0" />
    <v-list dense>
      <v-list-item
        color="white"
        to="/"
      >
        <v-list-item-icon>
          <v-icon>
            mdi-view-dashboard
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Main page
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider class="mx-0" />
    <!-- OWNED EVENTS -->
    <template>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            Your Events
          </v-list-item-content>
          <v-list-item-action>
            <example-dialog
              v-model="dialog"
              title="Create Event"
              :tabs="[
                {
                  name: 'General',
                  component: require('./CreateEvent/CreateEventGeneral').default,
                },
              ]"
              activator
            />
          </v-list-item-action>
        </v-list-item>
        <event-menu-item
          v-for="event in ownedEvents"
          :key="event.id"
          :event="event"
          :to="{ path: `/event/${event.slug}` }"
        />
      </v-list>
      <v-divider
        class="mx-0"
        :style="{ 'width': '100%' }"
      />
    </template>
    <!-- ACTIVE EVENTS -->
    <template v-if="activeEvents.length">
      <v-list>
        <div class="backdrop-5 px-4 py-2 mb-2">
          Active Events
        </div>
        <event-menu-item
          v-for="event in activeEvents"
          :key="event.id"
          :event="event"
          :to="`/event/${event.slug}`"
        />
      </v-list>
      <v-divider
        class="mx-0"
        :style="{ 'width': '100%' }"
      />
    </template>
    <!-- ENDED EVENTS -->
    <template v-if="endedEvents.length">
      <v-list>
        <div class="backdrop-5 o-60 px-4 py-2 mb-2">
          Ended Events
        </div>
        <event-menu-item
          v-for="event in endedEvents"
          :key="event.id"
          :event="event"
          :to="{ path: `/event/${event.slug}` }"
          muted
        />
      </v-list>
      <v-divider
        class="mx-0"
        :style="{ 'width': '100%' }"
      />
    </template>
    <!-- DROPPED EVENTS -->
    <template v-if="droppedEvents.length">
      <v-list>
        <div class="backdrop-5 o-60 px-4 py-2 mb-2">
          Dropped Events
        </div>
        <event-menu-item
          v-for="event in droppedEvents"
          :key="event.id"
          :event="event"
          :to="{ path: `/event/${event.slug}` }"
          muted
        />
      </v-list>
      <v-divider
        class="mx-0"
        :style="{ 'width': '100%' }"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ExampleDialog from './ExampleDialog.vue'
import EventMenuItem from './EventMenuItem'
export default {
  name: 'DashboardCoreDrawer',
  components: { ExampleDialog, EventMenuItem },
  props: {
  },
  data: () => ({
    events: [],
    dialog: false
  }),
  computed: {
    ...mapState('main', ['sideBarImage']),
    ...mapState('user', ['claims']),
    drawer: {
      get () {
        return this.$store.state.main.drawer
      },
      set (val) {
        this.SET_DRAWER(val)
      }
    },
    ownedEvents () {
      return this.events.filter(event => event.owner.id === this.claims.sub && event.is_enabled && event.is_active)
    },
    activeEvents () {
      return this.events.filter(event => event.owner.id !== this.claims.sub && event.is_enabled && event.is_active)
    },
    endedEvents () {
      return this.events.filter(event => event.is_enabled && !event.is_active)
    },
    droppedEvents () {
      return this.events.filter(event => !event.is_enabled)
    }
  },
  methods: {
    ...mapActions('main', ['SET_DRAWER'])
  },
  apollo: {
    events: {
      query: require('@/gql/getEvents').default
    }
  }
}
</script>
